// Browser Support
import "native-promise-only";
import Matches from "./App/Polyfills/Matches";
import CustomEvent from "./App/Polyfills/CustomEvent";

// Base
import _u from 'wtc-utility-helpers';
import CONFIG from './App/config';
import Utils from './App/Utils/All';
import {ExecuteControllers} from 'wtc-controller-element';
import { rmInit } from "./App/Controllers/ReduceMotion.js";

// Single Controllers
import "wtc-controller-viewports";
import Tracking from "./App/Nintendo/Tracking";
import './App/Controllers/Triforce';
import "./App/Controllers/Glossary";
import "./App/Controllers/Nav";
import "./App/Controllers/VideoHelper"
import "./App/Controllers/EqualHeight"
import "./App/Controllers/Facts"
import "./App/Controllers/NewsArticle";
import "./App/Controllers/NewsArticles";

// Page Controllers
import "./App/Pages/Home";
import "./App/Pages/About";
import "./App/Pages/Videos";
import "./App/Pages/TimelinePage";
import "./App/Pages/News";

// Auto update footer copyright year
// document.querySelector('.footer-main .cpdate').html = new Date().getFullYear();

// Start tracking.
let trackOpts = Utils.getTrackingOpts();
trackOpts.devMode = (CONFIG.CONST.ENV === 'DEV' || CONFIG.CONST.ENV === 'LOCAL') ? true : false;
window.trackOpts = trackOpts;
Tracking.init(trackOpts);

// Instanciate all controllers
ExecuteControllers.instanciateAll();

// Video buttons
Utils.initVideos();

// reduce motion
rmInit();
